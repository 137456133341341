import { useTransactionAdder } from '@state/transactions/hooks';
import {
  getLiquidMiningAddresss,
  getTokenFactoryAddress,
  getStableVAPEStakingAddress,
  getRewardsControllerAddress,
} from '@utils/addressHelpers';
import { useCallback, useMemo } from 'react';
import { Address, useContractWrite, usePrepareContractWrite } from 'wagmi';
import LiquidMiningABI from '@config/abi/LiquidMining';
import StableVAPEStakingABI from '@config/abi/StableVapeStaking';
import { ERC20ABI } from '@config/abi/erc20ABI';
import { DEFAULT_GAS_LIMIT } from '@config';
import { TokenFactoryABI } from '@/config/abi/TokenFactory';
import useActiveWagmi from './useActiveWagmi';
import { ZERO } from '@/config/constants';
import { RewardsControllerABI } from '@/config/abi/RewardsController';

export type VaporContract =
  | 'liquidMining'
  | 'xvape'
  | 'erc20'
  | 'memeFactory'
  | 'rewardsController';

type WagmiWriteConfig = {
  contractName: VaporContract;
  functionName: string;
  args: any[];
  address?: Address;
  onSuccessOverrides?: object;
  value?: bigint;
};
/**
 * UNIVERSAL WAGMI WRITE
 */
export const useWagmiWrite = ({
  contractName,
  functionName,
  onSuccessOverrides = { summary: `${functionName} success!` },
  value = ZERO,
  ...props
}: WagmiWriteConfig) => {
  const { chainId } = useActiveWagmi();
  const { abi, address } = useMemo(() => {
    switch (contractName) {
      case 'erc20': {
        return { abi: ERC20ABI, address: props?.address };
      }
      case 'liquidMining': {
        return {
          abi: LiquidMiningABI,
          address: getLiquidMiningAddresss(chainId),
        };
      }
      case 'memeFactory': {
        return {
          abi: TokenFactoryABI,
          address: getTokenFactoryAddress(chainId),
        };
      }
      case 'xvape': {
        return {
          abi: StableVAPEStakingABI,
          address: getStableVAPEStakingAddress(chainId),
        };
      }
      case 'rewardsController': {
        return {
          abi: RewardsControllerABI,
          address: getRewardsControllerAddress(chainId),
        };
      }
      default: {
        throw new Error('Invalid contract name');
      }
    }
  }, [chainId, contractName, props?.address]);

  const addTransaction = useTransactionAdder();

  const onSuccess = useCallback(
    ({ hash }) => {
      addTransaction(hash, onSuccessOverrides);
    },
    [addTransaction, onSuccessOverrides],
  );

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
    refetch,
  } = usePrepareContractWrite({
    ...props,
    abi,
    address,
    functionName,
    value,
  });

  const { error, isError, reset, writeAsync } = useContractWrite({
    ...config,
    //@ts-expect-error: result type is unknown. Override gas if estimation failed.
    gas: config?.req?.gas ?? DEFAULT_GAS_LIMIT,
    onSuccess,
  });

  return {
    contractAddress: address, //return address to reuse and not call getXAddress() again
    error,
    isError: isPrepareError ?? isError,
    prepareError,
    refetch,
    reset,
    writeAsync,
  };
};
